import { createMetricsConfigDataModel } from '../../data-models/metrics-config.data-model';
import { IMetricsDataModel } from '../../data-models/metrics.data-model';
import { customLabelsCalculator } from '../../pages/PortfolioOverview/components/OverviewTable/CustomLabelsCalculator';
import {
  afterProcessTransactions as afterProcessTransactionsBase,
  createMetricTotalProcessor,
  createTransactionsProcessor,
} from '../default/metrics';
import { IAfterProcessTransactionsContext, ITransactionProcessResult } from '../default/metrics.type';

const metricsConfig = createMetricsConfigDataModel({
  defaultLabel: 'Seed',
  defaultRule: { fundId: -1, overLabel: 'Core', value: 1000000, sortOrder: 0 },
  limitRules: [
    { fundId: 12, overLabel: 'Core', value: 3000000, sortOrder: 0 },
    { fundId: 6, overLabel: 'Core', value: 500000, sortOrder: 1 },
    { fundId: 7, overLabel: 'Core', value: 500000, sortOrder: 2 },
    { fundId: 8, overLabel: 'Core', value: 500000, sortOrder: 3 },
  ],
});

function afterProcessTransactions(
  metric: IMetricsDataModel & ITransactionProcessResult,
  context: IAfterProcessTransactionsContext
) {
  const res = afterProcessTransactionsBase(metric, context);
  const includedFundIds = new Set(Array.from(metric.includedFunds.keys()));

  return {
    ...res,
    seedCore: customLabelsCalculator(metricsConfig, includedFundIds, metric),
  };
}

export { createTransactionsProcessor, createMetricTotalProcessor, afterProcessTransactions };
